import * as React from "react"

import DoubleToolScreen from '../../../../components/doubleToolScreen'


const DoubleTool = ({ data }) => (
  <div>
    <DoubleToolScreen
      name="Pitch"
      img_name="pitch_logo.png"
      link="https://pitch.com/"

      name2="Google Slides"
      img_name2="google_slides_logo.png"
      link2="https://www.google.com/slides/"
      
      description="Pitch is a sleek modern version of Google Slides. Both are amazing software for designing slides and presentations directly on the web."
      twitterHandleTool="@Pitch"
    />
  </div>
)

export default DoubleTool;


